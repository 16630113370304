#toggle {
  display: none;
  width: 28px;
  height: 30px;
  margin: 30px auto 10px;
}

#toggle span:after,
#toggle span:before {
  content: "";
  position: absolute;
  left: 0;
  top: -9px;
}
#toggle span:after {
  top: 9px;
}
#toggle span {
  position: relative;
  display: block;
}

#toggle span,
#toggle span:after,
#toggle span:before {
  width: 100%;
  height: 5px;
  background-color: #888;
  transition: all 0.3s;
  border-radius: 2px;
}


/* menu appearance*/
#menu {
  position: absolute;
  width: 105%;
  height: 100vh;
  display: none;
  z-index: 4;
  background: #000000dc;
}
.burgerNav {
  color: #fff;
  font-size: 40px;
  font-weight: 600;
}
.close {
  position: absolute;
  right: 8px;
  top: 12px;
  color: white;
  font-size: 20px;
}
