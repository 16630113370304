footer {
    width: 100%;
    height: 250px;
    background: #000;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .social {
    display: flex;
    gap: 40px;
  }
  