@media (max-width: 320px) {
  #toggle {
    position: absolute;
    display: block;
    left: 20px;
  }
}
@media (max-width: 425px) {
  #toggle {
    position: absolute;
    display: block;
    left: 20px;
  }
}
@media (max-width: 768px) {
  #menu {
    width: 103%;
  }
  #toggle {
    position: absolute;
    display: block;
    left: 20px;
  }
}
