@media (max-width: 1440px) {
  .blog__title {
    font-size: 78px;
  }
}
@media (max-width: 1024px) {
  .blog__title {
    font-size: 68px;
  }
  .slide__title {
    font-size: 30px;
    font-weight: bold;
    line-height: 35px;
  }
  .slide__date {
    font-size: 20px;
  }
  .swiperButton {
    display: none;
  }
}
@media (max-width: 768px) {
  .blog__title {
    font-size: 50px;
  }
  .slide__title {
    font-size: 30px;
    font-weight: bold;
    line-height: 35px;
  }
  .slide__date {
    font-size: 20px;
  }
  .swiperButton {
    display: none;
  }
}
@media (max-width: 425px) {
  .slide__title {
    font-size: 25px;
    font-weight: bold;
    line-height: 25px;
  }
  .slide__date {
    font-size: 20px;
  }
  .swiperButton {
    display: none;
  }
}
@media (max-width: 320px) {
  .slide__text {
    display: block;
  }
  .slide__title {
    font-size: 25px;
    font-weight: bold;
    line-height: 25px;
  }
  .slide__date {
    font-size: 20px;
  }
  .swiperButton {
    display: none;
  }
}



