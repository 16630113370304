/* ------------------BLOG-------------------  */
.blog {
  background-image: url("../../img/blog_bg.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 864px;
}
.swiper-container {
  position: relative;
}

.swiper {
  max-width: 1670px;
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.slide__img {
  object-fit: cover;
  width: 100%;
  max-height: 387.69px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.slide__text {
  display: flex;
  justify-content: space-between;
  padding: 22px 18px 17px 26px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background: #393731cc;
}

.slide__title {
  font-size: 48px;
  font-weight: bold;
  line-height: 55px;
  color: var(--font-color);
}

.slide__date {
  display: flex;
  align-items: end;
  font-size: 24px;
  font-weight: bold;
  color: var(--font-color);
}

.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  right: 2% !important;
}
