.join {
  height: 1080px;
  background-image: url("../../img/join_bg.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.join__cards {
  display: flex;
  justify-content: center;
  gap: 86px;
}
.join__card {
  width: 500px;
  height: 548px;
  padding: 19px;
  background-image: url("../../img/card.png");
  background-repeat: no-repeat;
  border-radius: 20px;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background-color: #39373154;
}
.card2 {
  background-image: url("../../img/card2.png");
}
.card3 {
  background-image: url("../../img/card3.png");
}
.card__number {
  margin-bottom: 140px;
  padding-top: 29px;
  padding-left: 60px;
  font-size: 64px;
  color: #fff;
  font-weight: bold;
}
.card__title {
  margin-bottom: 82px;
  font-size: 24px;
  color: var(--font-color);
  font-family: Commissioner, sans-serif;
  font-weight: 300;
  line-height: 2.6rem;
}
.card__btn {
  width: 460px;
  height: 80px;
  font-size: 28px;
  font-family: Commissioner, sans-serif;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  border-radius: 8px;
}
.steam__btn {
  background: rgb(28, 42, 65);
  background: linear-gradient(
    180deg,
    rgba(28, 42, 65, 1) 0%,
    rgba(16, 102, 146, 1) 100%
  );
}
.launch__btn {
  background: rgb(71, 4, 19);
  background: linear-gradient(
    180deg,
    rgba(71, 4, 19, 1) 0%,
    rgba(135, 7, 36, 1) 100%
  );
}
.search__btn {
  background: transparent;
  border: 1px solid #f2e8d9;
}
.search__btn::before {
  content: "";
  background-image: url("../../img/online.png");
  width: 20px;
  height: 16px;
  display: inline-block;
  margin-right: 26px;
  background-repeat: no-repeat;
  vertical-align: middle;
}
.copy-success {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 20px;
  border-radius: 10px;
  font-size: 1.5em;
  text-align: center;
  z-index: 1000;
}

.online {
  text-align: center;
  line-height: 80px;
  margin-bottom: 45px;
}
.online__title {
  color: var(--font-color);
  font-size: 48px;
  font-weight: bold;
}
.online__value {
  position: relative;
  color: #fff;
  font-size: 96px;
  font-weight: bold;
}
.online__value::before {
  content: "";
  background-image: url("../../img/online.png");
  width: 20px;
  height: 16px;
  display: inline-block;
  margin-right: 15px;
  background-repeat: no-repeat;
  vertical-align: middle;
}
