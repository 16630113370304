header {
  position: fixed;
  z-index: 3;
  display: flex;
  width: 100%;
  height: 115px;
  justify-content: center;
  margin-bottom: 55px;
  padding-top: 10px;
  padding-right: 15px;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.5998774509803921) 73%,
    rgba(0, 0, 0, 0) 100%
  );
}
.header__nav {
  display: flex;
  justify-content: center;
  flex: 1;
  font-weight: 300;
}

.header__list {
  display: flex;
  justify-content: center;
  gap: 46px;
}

.header__link {
  font-size: 30px;
  color: var(--font-color);
  text-decoration: none;
}

.steam__link {
  position: absolute;
  right: 15px;
}

.user__info {
  position: absolute;
  right: 15px;
  display: flex;
  align-items: end;
  gap: 12px;
  cursor: pointer;
}
.user__text {
  line-height: 22px;
}
.user__name {
  font-size: 30px;
  font-weight: 500;
  color: var(--font-color);
  background: transparent;
}
.user__avatar {
  width: 60px;
  height: 60px;
  border: 1px solid #ddaf6a;
}
.user__balance {
  text-align: end;
  color: var(--font-color);
  font-size: 18px;
  font-weight: 600;
}
.light__balance {
  font-weight: 300;
}
.personal__office {
  display: none;
  position: absolute;
  top: 100px;
  right: 15px;
  padding: 5px;
  border-radius: 3px;
  background-color: #39373186;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  font-family: "Fira Sans Condensed", sans-serif;
}
.personalBtn {
  width: 248px;
  height: 40px;
  display: block;
  text-align: start;
  font-size: 16px;
  padding-left: 13px;
  border-radius: 3px;
  color: var(--font-color);
  background: transparent;
}
.personalBtn:hover {
  background-color: #28261fb2;
}
hr {
  border: 0.8px solid rgba(255, 255, 255, 0.315);
}
.active {
  font-weight: 600;
  color: #ddaf6a;
}
