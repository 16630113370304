@media (max-width: 1440px) {
  .join {
    height: auto;
    padding-bottom: 100px;
  }
  .join__title {
    font-size: 60px;
  }
  .join__cards {
    gap: 25px;
  }
  .join__card {
    width: 450px;
    max-height: 483px;
  }
  .card__number {
    margin-bottom: 60px;
  }
  .card__title {
    margin-bottom: 50px;
  }
  .card__btn {
    width: 400px;
  }
  .responsive-br {
    display: none;
  }
}

@media (max-width: 1024px) {
  .join {
    height: auto;
    padding-bottom: 100px;
  }
  .join__title {
    font-size: 60px;
  }
  .join__cards {
    display: block;
  }
  .join__card {
    width: 700px;
    max-height: 483px;
    margin: 0 auto;
    margin-bottom: 30px;
  }
  .card__number {
    margin-bottom: 60px;
  }
  .card__title {
    margin-bottom: 50px;
    font-size: 37px;
  }
  .card__btn {
    width: 660px;
    height: 100px;
    font-size: 40px;
  }
  .responsive-br {
    display: none;
  }
}

@media (max-width: 768px) {
  .join {
    height: auto;
    padding-bottom: 100px;
  }
  .join__title {
    font-size: 60px;
  }
  .join__cards {
    display: block;
  }
  .join__card {
    max-width: 655px;
    max-height: 483px;
    margin: 0 auto;
    margin-bottom: 30px;
  }
  .card__number {
    margin-bottom: 60px;
  }
  .card__title {
    margin-bottom: 50px;
    font-size: 30px;
  }
  .card__btn {
    max-width: 615px;
    height: 100px;
    font-size: 30px;
  }
  .responsive-br {
    display: none;
  }
}


@media (max-width: 425px) {
  .join {
    height: auto;
    padding-bottom: 100px;
  }
  .join__title {
    font-size: 40px;
  }
  .join__cards {
    display: block;
  }
  .join__card {
    max-width: 355px;
    max-height: 383px;
    margin: 0 auto;
    margin-bottom: 20px;
  }
  .card__number {
    margin-bottom: 17px;
  }
  .card__title {
    margin-bottom: 9px;
    font-size: 20px;
  }
  .card__btn {
    max-width: 315px;
    max-height: 80px;
    font-size: 22px;
  }
  .responsive-br {
    display: none;
  }
}


@media (max-width: 320px) {
  .join {
    height: auto;
    padding-bottom: 100px;
  }
  .join__title {
    font-size: 55px;
  }
  .join__cards {
    display: block;
  }
  .join__card {
    max-width: 282px;
    max-height: 383px;
    margin: 0 auto;
    margin-bottom: 20px;
  }
  .online__title {
    font-size: 40px;
  }
  .card__number {
    margin-bottom: 17px;
  }
  .card__title {
    margin-bottom: 9px;
    font-size: 20px;
  }
  .card__btn {
    max-width: 244px;
    max-height: 80px;
    font-size: 22px;
  }
  .responsive-br {
    display: none;
  }
}
