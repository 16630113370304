.container {
    height: 100vh;
    padding: 0 113px;
    background-image: url("../../img/blogpage_bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.blog__title {
    padding-top: 150px;
    font-size: 48px;
    color: var(--font-color);
    font-weight: bold;
}
.blog__description {
    font-size: 29px;
    color: var(--font-color);
    font-weight: bold;
}
.blog__created_at {
    font-size: 29px;
    color: var(--font-color);
    font-weight: bold;
}