@media (max-width: 768px) {
  .main__title {
    margin-left: 20px;
    padding-top: 160px;
    font-size: 58px;
    line-height: 3.9rem;
  }
}

@media (max-width: 425px) {
  .main__title {
    margin-left: 20px;
    padding-top: 120px;
    font-size: 48px;
    line-height: 2.7rem;
  }
}
@media (max-width: 320px) {
  .main__title {
    margin-left: 20px;
    padding-top: 120px;
    font-size: 42px;
    line-height: 2.7rem;
  }
}

