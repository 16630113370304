@import url("https://fonts.googleapis.com/css2?family=Fira+Sans+Extra+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Commissioner:wght@100..900&display=swap");
@import "reset.css";

html {
  scroll-behavior: smooth;
  background-color: #000;
}

:root {
  --font-color: #f2e8d9;
}

::-webkit-scrollbar {
  display: none;
}

body {
  font-family: "Fira Sans Extra Condensed", sans-serif;
}

.title {
  color: var(--font-color);
  text-align: center;
  padding: 54px 0px 45px 0px;
  font-size: 96px;
  font-weight: bold;
}

/* ---------------HEADER------------------------------------ */


/* -------------------MAIN------------------------------- */

.gradient-container {
  position: relative;
  width: 100%;
  height: 0;
}

.gradient {
  position: absolute;
  top: -116.5px;
  width: 100%;
  height: 225px;
  background: linear-gradient(180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 52%,
      rgba(0, 0, 0, 0) 100%);
}

.fixed_gradient {
  background-attachment: fixed;
  position: relative;
  z-index: 1;
}

/* --------------JOIN-------------------------  */

/* ----------------FOOTER----------------  */
footer {
  width: 100%;
  height: 250px;
  background: #000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.social {
  display: flex;
  gap: 40px;
}