@media (max-width: 768px) {
  header {
    height: 90px;
  }
  .header__list {
    gap: 20px;
    display: none;
  }
  .header__link {
    font-size: 25px;
  }
  .user__info {
    gap: 6px;
  }
}
@media (max-width: 425px) {
  header {
    height: 90px;
  }
  .header__list {
    gap: 20px;
    display: none;
  }
  .header__link {
    font-size: 25px;
  }
  .user__info {
    gap: 6px;
  }
  .user__name {
    font-size: 28px;
  }
}
@media (max-width: 320px) {
  header {
    height: 90px;
  }
  .header__list {
    gap: 20px;
    display: none;
  }
  .header__link {
    font-size: 25px;
  }
  .user__info {
    gap: 6px;
  }
  .user__name {
    font-size: 28px;
  }
}

