.hero-section {
    background-image: url("../../img/main_bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 1080px;
    background-attachment: fixed;
    position: relative;
    z-index: 1;
  }
  .main__title {
    margin-left: 113px;
    padding-top: 150px;
    font-size: 48px;
    color: var(--font-color);
    font-weight: bold;
    line-height: 3.7rem;
  }